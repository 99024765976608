<template>
	<el-container>
		<Nav-bar></Nav-bar>
		<el-header>
			<Search></Search>
			<Category :isHidden="isHidden"></Category>
		</el-header>
		<el-main><router-view></router-view></el-main>
		<el-footer>
			<Footer></Footer>
			<div class="company-desc">
				<div>
					版权所有 {{$config.companyName}}
					<a href="https://beian.miit.gov.cn/#/Integrated/index">渝ICP备20006289-1号</a>
					{{$config.appName}}全球汇
				</div>
				<div>Copyright © 2020 buyersvip.com All Rights Reserved</div>
			</div>
		</el-footer>
		<el-backtop :bottom="100"></el-backtop>
	</el-container>
</template>

<script>
	import NavBar from '@components/NavBar';
	import Search from '@components/Search';
	import Category from '@components/Category';
	import Footer from '@components/Footer';
	export default {
		name: 'Index',
		components: {
			NavBar,
			Search,
			Category,
			Footer
		},
		data() {
			return {};
		},
		mounted() {},
		methods: {}
	};
</script>

<style lang="less" scoped>
	.el-header {
		padding: 0;
		height: auto !important;
		background: #fff;
	}

	.el-main {
		padding: 0;
	}

	.content {
		width: 1232px;
		margin: 16px auto 16px auto;
	}

	.el-footer {
		padding: 0;
		height: auto !important;
	}
</style>