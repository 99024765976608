<template>
	<div class="footer">
		<div class="type-page">
			<div class="essay-title-list">
				<div class="each-box" v-for="(item, index) in navLsit" :key="index">
					<div class="one-title">{{ item.title }}</div>
					<div class="sub-title-box">
						<router-link class="sub-title-item" v-for="(child, index_sec) in item.articleList" :key="index_sec" :to="{ path: '/helpCenter/', query: { id: item.id, childId: child.id } }">
							{{ child.title }}
						</router-link>
					</div>
				</div>
			</div>
			<div class="app-each"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
	name: 'Footer',
	data: function() {
		return {
			navLsit: []
		};
	},
	computed: {
		...mapGetters(['getNavList'])
	},
	mounted: function() {
		let that = this;
		that.articleCategory();
	},
	watch: {
		getNavList(val) {
			let that = this;
			that.navLsit = val;
		}
	},
	methods: {
		...mapActions(['articleCategory']),
		articleCategory() {
			let that = this;
			that.$store.dispatch('articleCategory');
		}
	}
};
</script>

<style lang="less" scoped>
.footer {
	width: 100%;
	text-align: center;
	background: #282828;
	padding: 20px 0;
	.type-page {
		width: 1232px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		.essay-title-list {
			flex: 3;
			display: flex;
			justify-content: space-between;
			text-align: left;
			.each-box {
				.one-title {
					color: #fff;
					font-size: 14px;
					line-height: 30px;
				}
				.sub-title-box {
					display: flex;
					flex-direction: column;
					.sub-title-item {
						font-size: 12px;
						cursor: pointer;
						line-height: 24px;
						color: #999;
					}
				}
			}
		}
		.app-each {
			flex: 1;
		}
	}
}
</style>
