<template>
	<div class="category_box">
		<div class="left_menu">
			<div class="title" @mouseenter="isHidden = false">
				<i class="el-icon-menu"></i>
				全部商品分类
			</div>
			<div class="list" :hidden="isHidden" @mouseleave="isHidden = true">
				<div class="category_item" v-for="(item, index) in category" :key="index">
					<div v-if="item.children">
						<div class="title" @mouseenter="isShow = index">
							<i class="el-icon-location"></i>
							<span>{{ item.cate_name }}</span>
						</div>
						<!-- @mouseout="isShow = -1" -->
						<ul v-show="isShow == index" @mouseleave="isShow = -1">
							<li v-for="(sec_item, sec_index) in item.children" :key="sec_index">
								<!-- <span>{{ sec_item.cate_name }}</span> -->
								<div class="third_child">
									<router-link class="chlid_list" :to="{ path: '/goods_list', query: { loading: true, sid: sec_item.id, title: sec_item.cate_name } }">
										<span>{{ sec_item.cate_name }}</span>
									</router-link>
								</div>
							</li>
						</ul>
					</div>
					<div v-else>{{ item.cate_name }}</div>
				</div>
			</div>
		</div>
		<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="isCollapse">
			<el-menu-item index="0" class=""><router-link :to="{ path: '/home' }">首页</router-link></el-menu-item>
			<el-menu-item :index="index + 1" v-for="(item, index) in category" :key="index">
				<router-link :to="{ path: '/goods_list', query: { cid: item.id, title: item.cate_name } }">{{ item.cate_name }}</router-link>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
import { getCategory } from '@api/user';
export default {
	name: 'Category',
	data() {
		return {
			activeIndex: '0', // 头部导航栏选中的标签
			category: [],
			isShow: -1,
			isHidden: true
		};
	},
	mounted: function() {
		let that = this;
		that.loadCategoryData();
	},
	methods: {
		// 商品分类
		loadCategoryData() {
			let that = this;
			getCategory().then(res => {
				that.category = res.data;
				that.$nextTick(() => {});
			});
		}
	}
};
</script>

<style lang="less" scoped>
.category_box {
	display: flex;
	width: 1232px;
	margin: 0 auto;

	.left_menu {
		position: relative;
		width: 200px;
		text-align: center;
		z-index: 1000;

		> .title {
			height: 60px;
			line-height: 60px;
			background: #f60;
			color: #fff;

			i {
				padding-right: 6px;
			}
		}

		.list {
			position: absolute;
			width: 100%;
			background: #fff;

			.category_item {
				.title {
					height: 48px;
					line-height: 48px;

					i {
						padding-right: 10px;
					}
				}

				ul {
					margin: 0;
					padding: 20px;
					position: absolute;
					width: 992px;
					background: #fff;
					left: 200px;
					top: 0;
					display: flex;
					align-items: center;
					flex-direction: column;
					min-height: calc(100% - 40px);
					li {
						list-style: none;
						border-bottom: 1px solid #fafafa;
						line-height: 35px;
						font-size: 12px;
						width: 100%;
						> div {
							display: flex;
							> span {
								font-weight: bold;
								width: 120px;
							}
							.third_child {
								width: calc(100% - 120px);
								display: flex;
								flex-wrap: wrap;
								.chlid_list {
									cursor: pointer;
									margin: 0 12px;
								}
								.chlid_list:hover {
									color: #f60;
								}
							}
						}
					}
				}
			}
		}
	}

	.el-menu--horizontal {
		border-bottom: none;

		> .el-menu-item {
			color: #333;
			font-size: 15px;
			font-weight: bold;
			padding: 0 15px;
		}

		> .el-menu-item.is-active {
			border-bottom: none;
			color: #f60;
		}
	}
}
</style>
