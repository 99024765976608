<!-- 头部搜索 -->
<template>
	<div class="header_search">
		<router-link to="/"><el-image :src="logo" fit="fit"></el-image></router-link>
		<el-input :placeholder="goodstype" v-model="search" @change="searchGoods">
			<template slot="append">
				搜索
			</template>
		</el-input>
		<el-badge :value="getNum" class="item" style="display: none;">
			<el-button type="primary" icon="el-icon-tickets"><router-link to="/order/cart">进货单</router-link></el-button>
		</el-badge>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { trim } from '../utils/index.js';
import { getLogo } from '../api/user.js';
export default {
	name: 'Search',
	data() {
		return {
			goodstype: '请输入商品名称',
			search: '',
			logo: require('@/assets/images/logo_' + this.$config.appvesion + '.png'),
		};
	},
	computed: {
		...mapGetters(['getNum'])
	},
	mounted: function() {
		// this.getLogoImage();
		const token = window.sessionStorage.getItem('token');
		if (token) {
			this.$store.dispatch('aGetCartNum'); // 获取购物车数量
		}
	},
	methods: {
		...mapActions(['aGetCartNum']),
		async getLogoImage() {
			let that = this;
			getLogo(2).then(res => {
				that.logoUrl = res.data.logo_url;
			});
		},
		// 搜索商品
		searchGoods() {
			let that = this;
			var val = trim(that.search);
			if (val) {
				that.$router.push({
					path: '/goods_list',
					query: {
						s: val
					}
				});
				setTimeout(() => (that.search = ''), 500);
			}
		}
	}
};
</script>
<style lang="less" scoped>
.header_search {
	width: 1232px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	height: 100px;
	justify-content: space-between;

	.el-input {
		width: 50%;
		margin-left: 16%;
		border: 2px solid #f60;
	}

	.item {
		border: 2px solid #f60;

		button {
			background: transparent;
			color: #999;
			border: none;

			i {
				color: #f60;
				font-size: 15px;
			}
		}
	}
}
</style>
