<!--
 * 页面头部状态栏 
 -->
<template>
	<div class="topbar">
		<div class="nav">
			<div class="left-tips">欢迎来到{{ $config.appName}}全球汇</div>
			<ul>
				<li class="theme-color" v-if="!tokenStr">
					<router-link to="/login">登录</router-link>
				</li>
				<li class="theme-color" v-else>
					<router-link to="/order/account">尊敬的 {{ phone }}</router-link>
				</li>
				<li class="theme-color" v-if="!tokenStr">
					<router-link to="/register">免费注册</router-link>
				</li>
				<li v-else>
					<a @click="logout">安全退出</a>
				</li>
				<li>
					<router-link to="/order/list">我的订单</router-link>
				</li>
				<li>
					<router-link to="/merchant_settled">商户入驻</router-link>
				</li>
				<li>
					<router-link to="/order/account">我的账户</router-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		getUserInfo,
		getLogout
	} from '@api/user';
	export default {
		name: "NavBar",
		data() {
			return {
				tokenStr: window.sessionStorage.getItem('token'),
				phone: ''
			}
		},
		mounted: function() {
			let that = this;
			if (that.tokenStr) {
				that.getUserInfo()
			}
		},
		methods: {
			// 用户信息
			getUserInfo: function() {
				let that = this;
				getUserInfo().then(res => {
					that.yphone = res.data.phone;
					let reg = /^(\d{3})\d*(\d{4})$/;
					that.phone = that.yphone.replace(reg, '$1****$2');
				});
			},
			// 安全退出
			logout: function() {
				this.$dialog.confirm({
					mes: "确认退出登录?",
					opts: () => {
						getLogout()
							.then(res => {
								window.sessionStorage.clear('token');
								location.href = location.origin;
								this.$dialog.success(res.msg);
							})
							.catch(err => {
								this.$dialog.error(err.msg);
							});
					}
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	.topbar {
		height: 30px;
		line-height: 30px;
		background-color: #f4f4f4;
		color: #333;
		font-size: 12px;

		.nav {
			width: 1232px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: center;

			ul {
				list-style: none;
				margin: 0;
				display: flex;
				align-items: center;

				li {
					margin-left: 30px;
				}

				li.theme-color {
					color: #f60;

					a {
						color: #f60;
					}
				}
			}
		}
	}

	.topbar .nav li .el-button {
		color: #b0b0b0;
	}

	.topbar .nav .el-button:hover {
		color: #fff;
	}

	.topbar .nav a:hover {
		color: #f90;
	}

	a {
		cursor: pointer;
	}
</style>
